// Generated by Framer (6d82f59)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, getPropertyControls, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Phosphor } from "https://framerusercontent.com/modules/tYScH7LTqUtz5KUaUAYP/p8dptk4UIND8hbFWz9V7/Phosphor.js";
const PhosphorFonts = getFonts(Phosphor);
const PhosphorControls = getPropertyControls(Phosphor);

const enabledGestures = {j_COLNbVt: {hover: true}};

const cycleOrder = ["j_COLNbVt", "wg6NopFv9"];

const serializationHash = "framer-S4BAN"

const variantClassNames = {j_COLNbVt: "framer-v-c2qh2", wg6NopFv9: "framer-v-19817wk"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {Off: "wg6NopFv9", On: "j_COLNbVt"}

const getProps = ({arrow, click, height, id, width, ...props}) => { return {...props, QTKGeV1RK: arrow ?? props.QTKGeV1RK ?? "ArrowRight", variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "j_COLNbVt", xEjYPkbFW: click ?? props.xEjYPkbFW} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;arrow?: string;click?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, QTKGeV1RK, xEjYPkbFW, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "j_COLNbVt", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTapaqbe6j = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (xEjYPkbFW) {const res = await xEjYPkbFW(...args);
if (res === false) return false;}
})

const sharedStyleClassNames = []

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(scopingClassNames, "framer-c2qh2", className, classNames)} data-framer-name={"On"} data-highlight layoutDependency={layoutDependency} layoutId={"j_COLNbVt"} onTap={onTapaqbe6j} ref={ref ?? ref1} style={{backgroundColor: "var(--token-28507858-f454-4fc9-a78b-9b30e1152dd6, rgba(33, 33, 33, 0.2))", borderBottomLeftRadius: 100, borderBottomRightRadius: 100, borderTopLeftRadius: 100, borderTopRightRadius: 100, opacity: 1, ...style}} variants={{"j_COLNbVt-hover": {backgroundColor: "var(--token-dade266e-403f-4443-9daf-d9d6dfc8a9db, rgb(33, 33, 33))", opacity: 1}, wg6NopFv9: {opacity: 0.3}}} {...addPropertyOverrides({"j_COLNbVt-hover": {"data-framer-name": undefined}, wg6NopFv9: {"data-framer-name": "Off"}}, baseVariant, gestureVariant)}><ComponentViewportProvider ><motion.div className={"framer-1r3ddwc-container"} layoutDependency={layoutDependency} layoutId={"aKOKzVnzk-container"}><Phosphor color={"var(--token-dade266e-403f-4443-9daf-d9d6dfc8a9db, rgb(33, 33, 33))"} height={"100%"} iconSearch={"House"} iconSelection={QTKGeV1RK} id={"aKOKzVnzk"} layoutId={"aKOKzVnzk"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} weight={"regular"} width={"100%"} {...addPropertyOverrides({"j_COLNbVt-hover": {color: "var(--token-08b61550-35fb-4add-b195-65e8b56d3ea1, rgb(255, 255, 255))"}}, baseVariant, gestureVariant)}/></motion.div></ComponentViewportProvider></motion.div></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-S4BAN.framer-7gjofe, .framer-S4BAN .framer-7gjofe { display: block; }", ".framer-S4BAN.framer-c2qh2 { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 34px; justify-content: center; overflow: visible; padding: 0px; position: relative; width: 34px; }", ".framer-S4BAN .framer-1r3ddwc-container { flex: none; height: 14px; position: relative; width: 14px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-S4BAN.framer-c2qh2 { gap: 0px; } .framer-S4BAN.framer-c2qh2 > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-S4BAN.framer-c2qh2 > :first-child { margin-left: 0px; } .framer-S4BAN.framer-c2qh2 > :last-child { margin-right: 0px; } }", ".framer-S4BAN.framer-v-19817wk.framer-c2qh2 { cursor: default; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 34
 * @framerIntrinsicWidth 34
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"wg6NopFv9":{"layout":["fixed","fixed"]},"tX699nP73":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"QTKGeV1RK":"arrow","xEjYPkbFW":"click"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FrameriBuZVuGtY: React.ComponentType<Props> = withCSS(Component, css, "framer-S4BAN") as typeof Component;
export default FrameriBuZVuGtY;

FrameriBuZVuGtY.displayName = "Button/Arrow";

FrameriBuZVuGtY.defaultProps = {height: 34, width: 34};

addPropertyControls(FrameriBuZVuGtY, {variant: {options: ["j_COLNbVt", "wg6NopFv9"], optionTitles: ["On", "Off"], title: "Variant", type: ControlType.Enum}, QTKGeV1RK: PhosphorControls?.["iconSelection"] && {...PhosphorControls["iconSelection"], defaultValue: "ArrowRight", description: undefined, hidden: undefined, title: "Arrow"}, xEjYPkbFW: {title: "Click", type: ControlType.EventHandler}} as any)

addFonts(FrameriBuZVuGtY, [{explicitInter: true, fonts: []}, ...PhosphorFonts], {supportsExplicitInterCodegen: true})